<template>
    <div class='header-left-section flex align-items-center justify-content-start mb-0 md:mb-0'>

    <div class="align-items-center justify-content-start mb-0 md:mb-0">
              <h3 class="mb-1">{{ dataItem.name }}</h3>
              <div class="text-xl">{{ dataItem.projectName}} Projesi</div>
    </div>
         
    </div>
</template>

<script>
export default {
    name: 'FirmLeftPanel',
    props: {
        dataItem: {},
    },
};
</script>
