import appAxios from '@/utils/appAxios'

export default class StorageService {
    async getFolders(path) {
        const response = await appAxios.get('/api/Storage/directories?path='+path);

        if(response.status === 200)
        {
            return {
                isSuccess :true,
                data: response.data,
                message:""
            };
        }
        else{
            return {
                isSuccess: false,
                message: "Hata oluştu"
            }
        }
    }

    async getFiles(path) {
        const response = await appAxios.get('/api/Storage/files?path='+path);

        if(response.status === 200)
        {
            return {
                isSuccess :true,
                data: response.data,
                message:""
            };
        }
        else{
            return {
                isSuccess: false,
                message: "Hata oluştu"
            }
        }
    }

    async createFolder(path) {
        const response = await appAxios.post('/api/Storage/mkdir?path='+path);
        if(response.status === 200)
        {
            return {
                isSuccess :true,
                data: response.data,
                message:""
            };
        }
        else{
            return {
                isSuccess: false,
                message: "Hata oluştu"
            }
        }
    }

}