<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <FirmLeftPanel :data-item='dataItem'></FirmLeftPanel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Taşeron Ayarlar</h3>
					<p class="text-lg">
						Taşeron firma yetkilisi bilgilendirme servisinden gelecek içerikleri SMS veya E-Posta ile talep ediyorsa aktif hale getirin. E-Posta alanı boş ise bu servis çalışmayacaktır.<br>
                        <br>
                        SMS servisi aktif hale getirildiğinde günlük siparişler taşeron firma yetkilisine gönderilecek olup rakamların güncellenmesi için tarafınıza talepte bulunabileceklerdir.<br>
                        <br>
                        Siparişleri standart olan taşeron firmalar için SMS servisini aktif etmeyin.
                        <span></span>
                        <span class="text-red-500 font-bold ml-2 mr-2">*</span> işaretli alanlar zorunlu alanlardır.<br>
					</p>

				</Sidebar>

				</span>
                
            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <FirmTabMenu :firm-id='firmId'></FirmTabMenu>
                <div class='col-12'>

                    <div class='field grid mb-5'>
                        <label for='allowEmail' class='col-12 mb-2 md:col-2 md:mb-0'>E-Posta İzni</label>
                        <div class='col-12 md:col-10'>
                            <InputSwitch id='allowEmail' v-model='dataItem.allowEmail' />
                        </div>
                    </div>
                    <div class='field grid mb-5'>
                        <label for='allowGsm' class='col-12 mb-2 md:col-2 md:mb-0'>SMS İzni</label>
                        <div class='col-12 md:col-10'>
                            <InputSwitch id='allowGsm' v-model='dataItem.allowGsm' />
                        </div>
                    </div>

                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='saveButton' label='Kaydet' icon='pi pi-save' class='mr-2 mb-2' @click='save'></Button>
                        </div>
                    </div>


                </div>

            </div>

        </div>


    </div>

    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 80%" :breakpoints="{'960px': '75vw'}">
        <FileManager root-folder="./FirmaLogo" :return-field="returnField" @onFileSelected="onFileSelected"></FileManager>
    </OverlayPanel>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import FileManager from '../../components/fileManager/fileManager';
import FirmLeftPanel from './firmLeftPanel';
import FirmTabMenu from './firmTabMenu';
import FirmService from '../../services/firmService';
import { getFirm } from '../common/commonFunctions';

export default {
    components: { FirmTabMenu, FirmLeftPanel,  AdminToast, FileManager },
    _firmService: null,
    created() {
        this._firmService = new FirmService();
        this.firmId = this.$route.params.firmId;
    },
    async mounted() {
        this.dataItem = await getFirm(this.firmId);
    },
    data() {
        return {
            firmId: 0,
            dataItem: {
                name: '',
                isActive: true,
                email: '',
                phone: '',
                personName: '',
                personPhone: '',
                personEmail: '',
                firmLogo: 'ProjeLogo/LogoDefault.png',
                allowGsm: false,
                allowEmail: false
            },
            visibleLeft: false,
        };
    },
    methods: {
        async save() {

            if (this.validateForm()) {
                const updateResponse = await this._firmService.updateFirmSettings(this.firmId, this.dataItem);
                if (updateResponse.isSuccess) {
                    showSuccessMessage(this, 'Taşeron Ayarlar Güncelleme Başarılı');
                } else {
                    showErrorMessage(this, 'Taşeron Ayarlar Güncellemede Sorun İle Karşılaşıldı');
                }
            }
        },
        validateForm() {
            return true;
        },

    },
};

</script>


<style scoped>

</style>
