export async function createFolder(folder) {
    const fileResponse = await this._storageService.createFolder(folder)
    if (fileResponse.isSuccess) {
        this.files = fileResponse.data;
    }
}

export async function getFiles(folder) {
    const fileResponse = await this._storageService.getFiles(folder)
    if (fileResponse.isSuccess) {
        this.files = fileResponse.data;
    }
}

export async function getFolders() {
    const folderResponse = await this._storageService.getFolders(this.rootFolder);
    if (folderResponse.isSuccess) {
        this.folders = folderResponse.data;
        if (this.folders.length > 0) {
            this.selectedFolder = this.folders[0].data;
            this.uploadUrl = process.env.VUE_APP_MAIN_API + "/api/upload/image?path=" + this.selectedFolder;
            await getFiles.call(this, this.folders[0].data);
        }
    }
}