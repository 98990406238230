<template>
    <TabMenu :model='nestedRouteItems' class='mb-5' />
</template>
<script>

export default {
    name: 'FirmTabMenu',
    props: {
        firmId: {},
    },
    data() {
        return {
            nestedRouteItems: [],
        };
    },
    created() {
        this.nestedRouteItems =[
            {
                label: 'Temel Bilgiler',
                to: '/Firm/Edit/' + this.firmId,
            },
            {
                label: 'Resmi Bilgiler',
                to: '/Firm/Official/' + this.firmId,
            },
            {
                label: 'Dosyalar',
                to: '/Firm/Files/' + this.firmId,
            },
            {
                label: 'Ayarlar',
                to: '/Firm/Settings/' + this.firmId,
            },
            {
                label: 'Sipariş',
                to: '/Firm/DefaultQuantities/' + this.firmId,
            }
        ]
    },
};
</script>